<template>
    <div class="location-index">
        <md-tabs @change="tabChange">
            <md-tab md-label="Locations" :md-active="tabIndex==0">
                <location-list v-if="tabIndex==0"></location-list>
            </md-tab>
            <md-tab md-label="Add Location" :md-active="tabIndex==1">
                <location-edit v-if="tabIndex==1"></location-edit>
            </md-tab>
            <md-tab md-label="Edit Location" v-if="tabIndex==2" :md-active="tabIndex==2">
                <location-edit :id="id" v-if="tabIndex==2"></location-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import LocationList from "@/components/Location/LocationList";
import LocationEdit from "@/components/Location/LocationEdit";
export default {
    props: ["id"],
    components: {
        LocationList,
        LocationEdit
    },
    data() {
        return {
            tabIndex: 1
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push("/admin/locations");
            }
            if (index == 1) {
                this.$router.push(`/admin/locations/new`);
            }
            if (index == 2) {
                this.$router.push(`/admin/locations/${this.id}`);
            }
        }
    }
};
</script>

