<template>
    <div class="location-list">
        <list :fs="locationFields" api="locations" editLink="locations" title="Location" @deleted="reload"></list>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { locationFields } from "@/config";
import List from "@/components/List";

export default {
    data() {
        return {};
    },
    components: {
        List
    },
    computed: {
        locationFields() {
            return locationFields;
        }
    },
    methods: {
        async reload() {
            await this.$store.dispatch("location/reload");
        }
    },
    async created() {}
};
</script>