<template>
    <div class="location-edit">
        <edit api='locations' :id="id" :fs="locationFields" @saved="saved"></edit>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { locationFields } from "@/config";
import Edit from "@/components/Edit";
export default {
    props: ["id"],
    components: {
        Edit
    },
    computed: {
        locationFields() {
            return locationFields;
        }
    },
    methods: {
        saved() {
            this.$store.dispatch("location/reload");
            this.$router.push("/admin/locations");
            this.$store.dispatch("user/refreshProfile");
        }
    }
};
</script>
